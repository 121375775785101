<template>
  <div>
    <div class="modal fade" id="openedPO" data-backdrop="static" data-keyboard="false" tabindex="-1"
      aria-labelledby="openedPOLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="popup-title">
            <span class="modal-title" id="openedPOLabel">Confirm receipt of PO {{ po.poCode }}</span>
          </div>
          <div class="modal-body popup-body">
            <p>
              Please confirm the receipt of the purchase order to sea chefs.
            </p>
          </div>
          <div class="popup-buttons">
            <button type="button" class="popup-confirm" data-dismiss="modal" @click="confirmReceipt">Yes, I confirm the
              receipt</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmReceiptPo",  
  mounted: function () {
    $('#openedPO').modal('show');
  },
  methods: {
    confirmReceipt: function () {
      this.$emit('confirmed-receipt');
    }
  },
  props: ['po'],  
}
</script>
