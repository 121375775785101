<template>
  <div v-if="orderType" class="order-edit-body">
    <div class="row">
      <div class="legend bg-white col rounded-lg shadow m-1 p-2">
        <div class="legend-title">
          <strong>Keywords - paste in text as {{ "\{\{ keyword \}\}" }}</strong>
        </div>
        <div class="keywords">
          <dl>
            <dt>id</dt><dd>ID</dd>
            <dt>deliveryInfo</dt><dd>Delivery Info</dd>
            <dt>deliveryAddress</dt><dd>Delivery Address</dd>
            <dt>deliveryPort</dt><dd>Delivery Port</dd>
            <dt>vesselId</dt><dd>Vessel ID</dd>
            <dt>vesselName</dt><dd>Vessel Name</dd>
            <dt>userEmail</dt><dd>User Email</dd>
            <dt>poUser</dt><dd>PO User</dd>
            <dt>poCode</dt><dd>PO Code (Summary)</dd>
            <dt>voyageNumber</dt><dd>Voyage Number</dd>
            <dt>vendorCode</dt><dd>Vendor Code</dd>
            <dt>vendorName</dt><dd>Vendor Name</dd>
            <dt>vendorAddress</dt><dd>Vendor Address</dd>
            <dt>vendorPhone</dt><dd>Vendor Phone</dd>
            <dt>vendorFax</dt><dd>Vendor Fax</dd>
            <dt>vendorEmailsString</dt><dd>Vendor Emails (Concatenated)</dd>
            <dt>vendorCurrency</dt><dd>Vendor Currency</dd>
            <dt>boardingDate</dt><dd>Boarding Date</dd>
            <dt>deliveryDate</dt><dd>Delivery Date</dd>
            <dt>commentBody</dt><dd>Order Comment Body</dd>
            <dt>deliveryInstructions</dt><dd>Delivery Instructions</dd>
            <dt>total</dt><dd>Total</dd>
            <dt>weight</dt><dd>Weight</dd>
            <dt>statusString</dt><dd>Order Status</dd>
            <dt>freightCost</dt><dd>Freight Cost</dd>
            <dt>PO_SINGLE_LOGIN</dt><dd>Vendor Link - Single Order</dd>
            <dt>PO_LIST_LOGIN</dt><dd>Vendor Link - Orders Overview</dd>
            <dt>warehouseLocator1</dt><dd>Warehouse Locator1</dd>
            <dt>warehouseLocator2</dt><dd>Warehouse Locator2</dd>
            <!-- <dt>TEXT_FOR_NOT_VENDORPORTAL_USER</dt><dd>"Use Link to download purchase order."</dd>
            <dt>TEXT_FOR_NOT_VENDORPORTAL_USER_GERMAN</dt><dd>"Downloadseite PO als PDF, XLSX und Anhängen"</dd> -->
          </dl>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col white-box2 p-2">
            <div class="row py-1 align-items-center">
              <div class="col-2 font-weight-bold">Order Type ID:</div>
              <div class="col"><input type="text" v-model="orderType.orderTypeId"></div>
            </div>
            <div class="row py-1 align-items-center">
              <div class="col-2 font-weight-bold">Name:</div>
              <div class="col"><input type="text" v-model="orderType.orderTypeName"></div>
            </div>
            <div class="row py-1 align-items-center">
              <div class="col-2 font-weight-bold">Email Subject:</div>
              <div class="col"><input type="text" v-model="orderType.emailSubject"></div>
            </div>
            <!-- NOTE Kein OrderType Status mehr wechselbar, unnötig siehe VPS-118 -->
            <!-- <div class="row" v-if="orderType.id !== null">
              <div class="col-2"><strong>Status:</strong></div>
              <div class="col">{{ orderType.statusString }}</div>
            </div> -->
          </div>
        </div>
        <div class="row">
          <div class="emails white-box2 col-sm-8 p-2">
            <div class="row">
              <div class="col-6">
                <div>
                  <strong>To:</strong>
                  <i class="fas fa-plus-circle fa-1x" @click="orderType.emailsTo.push('')" style="cursor: pointer;"></i>
                  <ul>
                    <li v-for="(email, index) of orderType.emailsTo" :key="index" class="py-1">
                      <input class="email-input" type="email" v-model="orderType.emailsTo[index]">
                      <i class="fas fa-minus-circle fa-1x" @click="orderType.emailsTo.splice(index, 1)" style="cursor: pointer;"></i>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-6">
                <div>
                  <strong>CC:</strong>
                  <i class="fas fa-plus-circle fa-1x" @click="orderType.emailsCC.push('')" style="cursor: pointer;"></i>
                  <ul>
                    <li v-for="(email, index) of orderType.emailsCC" :key="index" class="py-1">
                      <input class="email-input" type="email" v-model="orderType.emailsCC[index]">
                      <i class="fas fa-minus-circle fa-1x" @click="orderType.emailsCC.splice(index, 1)" style="cursor: pointer;"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="attachments white-box2 col-sm">
            <strong>Attachments:</strong>
            <span v-if="orderType.id !== null">
              <label for="orderTypeAttachment">
              <i class="fas fa-plus-circle fa-1x" style="cursor: pointer;"></i>
            </label>
            <input type="file" id="orderTypeAttachment" ref="orderTypeAttachment" style="display: none" multiple @change="addAttachments()">
            <ul v-for="attachment in orderType.orderTypeAttachments">
              <li>
                <i class="far fa-file fa-1x"></i> {{ attachment.originalName }}
                <i class="fas fa-minus-circle fa-1x" @click="removeAttachment(attachment.id)" style="cursor: pointer;"></i>
              </li>
            </ul>
            </span>
            <p v-else>Adding attachments will be possible after saving new order type.</p>
          </div>
        </div>
        <div class="row">
          <div class="body white-box2 col p-2">
            <div><strong>Email Body</strong></div>
            <div>
              <vue-editor v-model="orderType.emailBody" :editor-toolbar="customToolbar"></vue-editor>
            </div>
          </div>
        </div>
      </div>      
    </div>
    <div class="row">
      <div class="col white-box2 p-2">        
        <div class="row py-1 align-items-center">
          <div class="col-2 font-weight-bold">Email Subject For Resubmit:</div>
          <div class="col"><input type="text" v-model="orderType.emailSubjectResubmit"></div>
        </div>        
      </div>
    </div>
    <div class="row">
      <div class="body white-box2 col p-2">
        <div><strong>Email Body For Resubmit</strong></div>
        <div>
          <vue-editor v-model="orderType.emailBodyResubmit" :editor-toolbar="customToolbar"></vue-editor>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col white-box2 p-2">        
        <div class="row py-1 align-items-center">
          <div class="col-2 font-weight-bold">Email Subject For Cancelation:</div>
          <div class="col"><input type="text" v-model="orderType.emailSubjectCancel"></div>
        </div>        
      </div>
    </div>
    <div class="row">
      <div class="body white-box2 col p-2">
        <div><strong>Email Body For Cancelation</strong></div>
        <div>
          <vue-editor v-model="orderType.emailBodyCancel" :editor-toolbar="customToolbar"></vue-editor>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center spinner" v-if="isSpinner"></div>
  </div>
</template>

<script>
    const axios = require('axios').default;
    import { VueEditor } from 'vue2-editor';

    export default {
      name: "OrderTypeEdit",
      data: function () {
        return {
          isSpinner: false,
          orderType: undefined,
          isDirty: false,
          customToolbar: [
            [{ 'size': [] }],
            [ 'bold', 'italic', 'underline', 'strike' ],
            [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
            ['blockquote'],
          ]
        }
      },
      components: {
        VueEditor,
      },
      methods: {
        initEmptyOrderType: function () {
          return {
            id: null,
            orderTypeId: '',
            orderTypeName: '',
            emailSubject: '',
            emailSubjectResubmit: '',
            emailSubjectCancel: '',
            emailBody: '',
            emailBodyResubmit: '',
            emailBodyCancel: '',
            emailsTo: [],
            emailsCC: [],
            status: 0,
          };
        },
        loadData: function() {
          axios.get('/order-type/' + this.id + '/json')
              .then(this.loadDataSuccess)
              .catch(this.loadDataError)
              .finally(this.loadDataFinally);
        },
        loadDataSuccess: function(response) {
          this.orderType = response.data.data;
        },
        loadDataError: function(error) {
          console.debug(error);
          let message = 'There has been an error while loading the data.';
          if (error.response && error.response.data && error.response.data.errors) {
            message += `\n`;
            for (let key of Object.keys(error.response.data.errors)) {
              let errorMessage = error.response.data.errors[key];

              message += `\n${key}: ${errorMessage}`;
            }
          }
          this.flash('error', message);
        },
        loadDataFinally: function() {
        },
        flash: function (type, message) {
          this.$emit('flash', {type: type, message: message});
        },
        addAttachments: function () {
          let uploadedAttachments = this.$refs.orderTypeAttachment.files;
          let formData = new FormData();
          for (let i = 0; i < uploadedAttachments.length; i++) {
            let uploadedAttachment = uploadedAttachments[i];
            formData.append(`orderTypeAttachment[${i}]`, uploadedAttachment);
          }

          this.isSpinner = true;

          axios.post('/order-type/' + this.orderType.id + '/attachment/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
              .then(this.attachmentUploadSuccess)
              .catch(this.attachmentError)
              .finally(this.attachmentFinally('Attachment successfully uploaded.'));
        },
        attachmentUploadSuccess: function(response) {
          //console.debug(response);
          this.orderType.orderTypeAttachments.push({"id": response.data.orderTypeAttachment.id, "originalName": response.data.orderTypeAttachment.originalName});
        },
        attachmentError: function(error) {
          console.debug(error);
          let message = 'There has been an error while updating the attachments. Please check the validation errors and try again.';
          // for (let error of error.)
          if (error.response && error.response.data && error.response.data.errors) {
            message += `\n`;
            for (let key of Object.keys(error.response.data.errors)) {
              let errorMessage = error.response.data.errors[key];

              message += `\n${key}: ${errorMessage}`;
            }
          }
          alert(message)
        },
        attachmentFinally: function(message) {
          this.isSpinner = false;
          this.resetFileInput();  // Input zurücksetzen
          this.flash('success', message);
        },
        removeAttachment: function (orderTypeAttachmentId) {
          let orderTypeAttachment = null;
          for (let attachment of this.orderType.orderTypeAttachments) {
            if (attachment.id === orderTypeAttachmentId) {

              orderTypeAttachment = attachment;
              break;
            }
          }
          if (! orderTypeAttachment) {
            return Promise.reject(`Invalid order type ID ${orderTypeAttachmentId}`);
          }

          this.isSpinner = true;

          axios.post(`/orderType/${this.orderType.id}/attachment/${orderTypeAttachmentId}/remove`)
            .then(response => {
              this.attachmentRemoveSuccess(response);            
            })
            .catch(this.attachmentError)
            .finally(this.attachmentFinally('Attachment successfully removed.'));
        },
        resetFileInput() {
            this.$refs.orderTypeAttachment.value = null;  // Datei-Input zurücksetzen
        },
        attachmentRemoveSuccess: function(response) {
          //console.debug(response);
          let attachmentId = response.data.orderTypeAttachment.id;
          for (let [i, orderTypeAttachment] of this.orderType.orderTypeAttachments.entries()) {
            if (orderTypeAttachment.id === attachmentId) {
              this.orderType.orderTypeAttachments.splice(i, 1);
            }
          }
        },
      },
      props: {
        'id': {
          type: Number,
          default: undefined,
        }
      },
      computed: {

      },
      mounted: function () {
        if (this.id) {
          this.loadData();
        } else {
          this.orderType = this.initEmptyOrderType();
        }
      }
    }
</script>
