<template>
    <div>
        <div class="modal fade" id="confirm-po">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="popup-title">
                        <span class="modal-title">Confirm PO {{ po.poCode }}</span>
                    </div>
                    <div class="modal-body popup-body">                        
                        <p>
                            Please make sure that all confirmed quantities and prices are correct.
                            Do you want to confirm this PO?
                        </p>                        
                    </div>
                    <div class="popup-buttons">
                        <button type="button" class="popup-confirm" :disabled="! isFilled" data-dismiss="modal" @click="confirm">Yes, confirm</button>
                        <button type="button" class="popup-cancel" data-dismiss="modal">Abort</button>
                    </div>
                </div>
            </div>
        </div>
        <button v-if="isFilled" class="buttons" data-toggle="modal" data-target="#confirm-po" :disabled="! isUnblocked">Confirm PO</button>
        <button v-else class="buttons" :disabled="! isUnblocked" @click="abort">Confirm PO</button>
    </div>
</template>

<script>
  export default {
      name: "ConfirmPo",
      methods: {
          confirm: function () {
              this.$emit('confirmed');
          },
          abort: function () {
              this.$emit('aborted');
          },
      },
      props:['is-unblocked', 'is-filled', 'po'],
  }
</script>
