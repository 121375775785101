<template>
  <div class="white-box2">
    <div class="col-10">
      <div class="row">
        <div class="col-4"><strong>Add Beiladerliste-Scheduler</strong></div>
      </div>
      <div class="row my-1">
        <div class="col-1"><strong>Name / Subject:</strong></div>
        <div class="col-5"><input type="text" v-model="subject" ></div>
      </div>
      <div class="row my-1">
        <div class="col-1"><strong>Consolidators:</strong></div>
        <div class="col-5">
          <select v-model="addConsolidator" multiple size="6" class="w-100">
            <option v-for="item in consolidators" :key="item.id" v-if="item.status" :value="item.name">{{ item.code }} - {{ item.name }}</option>
          </select>
        </div>
      </div>


      <div class="row my-1">
        <div class="col-1">
          <strong>Emails:</strong>
          <i class="fas fa-plus-circle fa-1x" @click="emails.push('')"></i>
        </div>
      </div>    
      <div class="row" v-for="(email, index) of emails" :key="index">
          <div class="col-1"></div>
          <div class="col-5 d-flex align-items-center">
            <input class="w-100 email-input" type="email" v-model="emails[index]">
            <i class="fas fa-minus-circle fa-1x" @click="emails.splice(index, 1)"></i>
          </div>
      </div>
      <div class="row mt-3 mb-1">
        <div class="col-1"><strong>Time of day:</strong></div>
        <div class="col"><input type="time" v-model="timeOfDay"></div>
      </div>
      <div class="row my-1">
        <div class="col-1"><strong>Weekday:</strong></div>
        <div class="col-1" v-for="choice in choices">
          <label class="bls-label"><input type="checkbox" class="bls-input" v-model="weekdays" :id="choice.id"
              :value="choice.value">{{ choice.name }}</label>
        </div>
      </div>
    </div>
    <div class="col d-flex align-items-center">
      <button class="buttons beil-btn" :disabled="!id" @click="empty">Clear</button>
      <button class="buttons beil-btn" :disabled="!subject || addConsolidator.length === 0 || emails.length === 0 || timeOfDay === null || weekdays.length === 0 " @click="save">Save</button>
<!--       <form :action="actionLink" method="post">
        <input type="hidden" name="consolidator" :value="consolidator">
        <button class="buttons beil-btn" :disabled="!consolidator"><i class="fas fa-download beil-icon"></i></button>
      </form> -->
    </div> 
    <div class="d-flex justify-content-center spinner" v-if="spinner"></div>
  </div>
</template>

<script>
const axios = require('axios').default;

export default {
  name: "BeiladerlisteSchedulerAdd",
  data: function () {
    return {
      spinner: false,
      id: null,
      consolidator: '',
      addConsolidator: [],
      consolidators: [],
      subject: '',
      emails: [],
      timeOfDay: null,
      choices: [
        {
          'value': 1,
          'name': 'Monday'
        },
        {
          'value': 2,
          'name': 'Tuesday'
        },
        {
          'value': 3,
          'name': 'Wednesday'
        },
        {
          'value': 4,
          'name': 'Thursday'
        },
        {
          'value': 5,
          'name': 'Friday'
        },
        {
          'value': 6,
          'name': 'Saturday'
        },
        {
          'value': 7,
          'name': 'Sunday'
        },],
      weekdays: [],
    }
  },
  props: ['actionLink'],
  methods: {
    save: function () {
      this.spinner = true;
      let beiladerlisteScheduler = this.$data;
      //console.debug(beiladerlisteScheduler);
      axios.post('/settings/beiladerliste-scheduler/save', beiladerlisteScheduler)
        .then(this.saveSuccess)
        .catch(this.saveError)
        .finally(this.saveFinally);
    },
    saveSuccess: function () {
      this.$parent.flashbag.success.push("Successfully saved order type.");
      window.location.href = '/settings/beiladerliste-scheduler'
    },
    saveError: function (error) {
      //console.debug(error);
      let message = 'There has been an error while saving the changes. Please check the validation errors and try again.';
      if (error.response && error.response.data && error.response.data.errors) {
        message += `\n`;
        for (let key of Object.keys(error.response.data.errors)) {
          let errorMessage = error.response.data.errors[key];

          message += `\n${key}: ${errorMessage}`;
        }
      }
      this.$parent.flashbag.error.push(message);
    },
    saveFinally: function () {
      this.spinner = false;
    },
    fetchConsolidators: function () {
                let self = this;
                self.spinner = true;
                self.consolidators = [];

                axios.get('/settings/consolidators/list/json')
                    .then(function (response) {
                        self.consolidators = response.data.data;
                    })
                    .catch(function (error) {
                        //console.log(error);
                    })
                    .then(function () {
                        // always executed
                        self.spinner = false;
                    });
            },
    editSchedule: function(data, id, subject, emails, addConsolidator, timeOfDay, weekDays) {
      this.id = id;
      this.subject = subject;
      this.emails = emails.split(','); // Teilen Sie die Zeichenkette in ein Array auf
      this.addConsolidator = addConsolidator.split(' | ');
      this.timeOfDay = timeOfDay;
      weekDays = weekDays.split('; ');
      this.weekdays=[];
      for (let day of weekDays) {
        switch (day) {
          case 'Monday':
            day = 1;
            break;
          case 'Tuesday':
            day = 2;
            break;
          case 'Wednesday':
            day = 3;
            break;
          case 'Thursday':
            day = 4;
            break;
          case 'Friday':
            day = 5;
            break;
          case 'Saturday':
            day = 6;
            break;
          case 'Sunday':
            day = 7;
            break;
          }
        this.weekdays.push(day);        
      }
      
    },
    empty: function() {
      this.id = null;
      this.subject = '';
      this.emails = [];
      this.addConsolidator = [];
      this.consolidator = '';
      this.timeOfDay = null;
      this.weekdays = [];
    },
  },
  mounted: function () {
    this.fetchConsolidators()
  },
}
</script>

<style scoped></style>