<template>
  <div>
    <div class="modal fade" id="canceledPO" data-backdrop="static" data-keyboard="false" tabindex="-1"
      aria-labelledby="canceledPOLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="popup-title">
            <span class="modal-title" id="canceledPOLabel">Confirm cancelation of PO {{ po.poCode }}</span>
          </div>
          <div class="modal-body popup-body">
            <p>
              Please confirm the cancelation of the PO.
              By clicking on "Yes" sea chefs will get informed automatically.
            </p>
          </div>
          <div class="popup-buttons">
            <button type="button" class="popup-cancel" data-dismiss="modal" @click="abortCancelation">No</button>
            <button type="button" class="popup-confirm" data-dismiss="modal" @click="confirmCancelation">Yes, I confirm the
              cancelation</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmCanceledPo",
  mounted: function () {
    $('#canceledPO').modal('show');
  },
  methods: {
    confirmCancelation: function () {
      this.$emit('confirmed');
    },
    abortCancelation: function () {
      this.$emit('abortCancelation');
      window.location.href = "/po";
    }
  },
  props: ['po'],
}
</script>
