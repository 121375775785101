window.$ = window.jQuery = require('jquery')
window.Vue = require('vue/dist/vue.esm').default;
require('popper.js')
require('bootstrap')
require('./consolidator-edit')
require('./consolidator-overview')
require('./vendor')
require('./vendor-settings-header')
require('./single-bid')
require('./bid-overview')
require('./po-overview')
require('./po-details')
require('./po-downloads')
require('./order-type-create')
require('./order-type-edit')
require('./settings-emails')
require('./beiladerliste-scheduler')
