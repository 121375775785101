const axios = require("axios").default;

import Sorter from "../components/Sorter";
import Pagination from "../components/Pagination";
import VueFlashbag from "../components/VueFlashbag";
import OpenedPO from "../components/OpenedPoDownloads.vue";
import CanceledPO from "../components/CanceledPoDownloads.vue";
import ResubmittedPO from "../components/ResubmittedPoDownloads.vue";

if (document.querySelector(".vue-po-downloads")) {
	Vue.component("sorter", Sorter);
	Vue.component("pagination", Pagination);
	Vue.component("vue-flashbag", VueFlashbag);
  Vue.component("resubmitted-po", ResubmittedPO);
  Vue.component("canceled-po", CanceledPO);
	Vue.component("opened-po", OpenedPO);

	const STATUS_READY_FOR_RECEIPT = 5;
	const STATUS_CANCELED = 20;
	const STATUS_CLOSED = 60;

	Number.prototype.countDecimals = function() {
		if (Math.floor(this.valueOf()) === this.valueOf() && this !== "") return 0;
		return this.toString().split(".")[1].length || 0;
	};

	document.appPoDetails = new Vue({
		el: ".vue-po-downloads",
		name: "po downloads",
		data: {
			po: {},
			isProcessing: false,
			spinner: false,
			flashbag: {
				success: [],
				error: [],
				info: [],
			},
			filterCPN: "",
			filterVPN: "",
			filterProductName: "",
			singlePricesDecimal: 0,
			totalPricesDecimal: 0,
			page: 1,
			limit: 50,
			downloadTimeout: undefined,
      filesToShow: [],
		},
		mounted: function() {
			this.po = JSON.parse(
				Buffer.from(this.$refs.poJson.dataset.poJson, "base64")
			);
			this.singlePricesDecimal = parseInt(
				this.$refs.singlePricesDecimal.dataset.singlePricesDecimal
			);
			this.totalPricesDecimal = parseInt(
				this.$refs.totalPricesDecimal.dataset.totalPricesDecimal
			);
      this.filesToShow = JSON.parse(
        Buffer.from(this.$refs.poAttachments.dataset.poAttachments, "base64")
			);
		},
		methods: {
	  formatNumberToGerman: function(value) {
				const number = parseFloat(value);
				if (isNaN(number)) {
					return value; // Wenn der Wert keine gültige Zahl ist, gib den ursprünglichen Wert zurück
				}
			
				// Überprüfe, ob der Wert Dezimalstellen hat und wie viele
				const decimalPlaces = value.includes('.') ? value.split('.')[1].length : 0;
			
				return new Intl.NumberFormat('de-DE', { minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces }).format(number);
			},
      confirmReceipt: function() {
				this.isProcessing = true;
				window.location.href = "/po/" + this.po.id + "/receipt";
			},
      confirmCancelation: function() {
				this.isProcessing = true;
				window.location.href = "/po/" + this.po.id + "/cancel";
			},
      confirmResubmit: function() {
				this.isProcessing = true;
				window.location.href = "/po/" + this.po.id + "/resubmit";
			},
			startDownload: function() {
        this.spinner = true;
        this.setCookie("downloadStarted", 0, 100); //Expiration could be anything... As long as we reset the value
        setTimeout(this.checkDownloadCookie, 1000); //Initiate the loop to check the cookie.
			},			
			setCookie: function(name, value, expiracy) {
				var exdate = new Date();
				exdate.setTime(exdate.getTime() + expiracy * 1000);
				var c_value =
					escape(value) +
					(expiracy == null ? "" : "; expires=" + exdate.toUTCString());
				document.cookie = name + "=" + c_value + "; path=/";
			},
			getCookie: function(name) {
				var i,
					x,
					y,
					ARRcookies = document.cookie.split(";");
				for (i = 0; i < ARRcookies.length; i++) {
					x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
					y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
					x = x.replace(/^\s+|\s+$/g, "");
					if (x == name) {
						return y ? decodeURI(unescape(y.replace(/\+/g, " "))) : y; //;//unescape(decodeURI(y));
					}
				}
			},
			checkDownloadCookie: function() {
				if (this.getCookie("downloadStarted") == 1) {
					this.setCookie("downloadStarted", "false", 100); //Expiration could be anything... As long as we reset the value
					this.spinner = false;
				} else {
					this.downloadTimeout = setTimeout(this.checkDownloadCookie, 1000); //Re-run this function in 1 second.
				}
			},
			isCanceledComplete: function(item) {
				if (this.isPastInvoicing) {
					return true;
				}
			},
			toFixed: function(value, decimalNumber) {
				let parsedValue = parseFloat(value);
				if (isNaN(parsedValue)) {
					return "";
				}
				parsedValue =
					Math.round(parsedValue * Math.pow(10, decimalNumber)) /
					Math.pow(10, decimalNumber);
				return parsedValue.toFixed(decimalNumber);
			},      
			onFilterInput: function() {
				this.page = 1;
			},
			changePage: function(page) {
				this.page = page;
			},
		},
		computed: {
			isReceiptPopup: function() {
        let showModal = false;
        if (this.po.status === STATUS_READY_FOR_RECEIPT && !this.po.resubmitted) {
          showModal = true;
        }
				return showModal;
			},
      isCancelationPopup: function() {
        let showModal = false;
        if (this.po.mistralStatus === 4 && this.po.status !== STATUS_CANCELED) {
          showModal = true;
        }
				return showModal;
			},
      isResubmitPopup: function() {
        let showModal = false;
        if (!this.po.resubmittedConfirmed && this.po.resubmitted) {
          showModal = true;
        }
        return showModal;
			},
			isPastInvoicing: function() {
				return [STATUS_CANCELED, STATUS_CLOSED].includes(this.po.status);
			},
			itemsOrderedTotal: function() {
				let total = 0;
				if (this.po && this.po.items) {
					for (let key of Object.keys(this.po.items)) {
						if (this.po.items[key].total == null) {
							total += 0;
						} else {
							total += parseFloat(this.po.items[key].total);
						}
					}
				}
				return total.toFixed(this.totalPricesDecimal);
			},
			itemsOrderedTotalFiltered: function() {
				let total = 0;
				if (this.po && this.filteredItems) {
					for (let key of Object.keys(this.filteredItems)) {
						if (this.filteredItems[key].total == null) {
							total += 0;
						} else {
							total += parseFloat(this.filteredItems[key].total);
						}
					}
				}
				return total;
			},
			filteredItems: function() {
				let filteredItems = [];
        if (this.po && this.po.items) {
          for (let [index, item] of this.po.items.entries()) {
            if (this.filterCPN) {
              if (item.customerProductNumber != undefined) {
                if (!item.customerProductNumber.includes(this.filterCPN)) {
                  continue;
                }
              } else {
                continue;
              }
            }
            if (this.filterVPN) {
              if (item.vendorProductNumber != undefined) {
                if (!item.vendorProductNumber.includes(this.filterVPN)) {
                  continue;
                }
              } else {
                continue;
              }
            }
            if (this.filterProductName) {
              if (item.summary != undefined) {
                if (!item.summary.includes(this.filterProductName)) {
                  continue;
                }
              } else {
                continue;
              }
            }
            filteredItems[index] = item;
          }
        }
        filteredItems = filteredItems.filter(function(n) {
          return n != undefined;
        });
        return filteredItems;
			},
			itemsToShow: function() {
				let filtered = this.filteredItems;

				if (!filtered || filtered.length < 0) {
					return filtered;
				}
				filtered = filtered.filter(function(n) {
					return n != undefined;
				});
				filtered = filtered.slice(
					(this.page - 1) * this.limit,
					this.page * this.limit
				);

				return filtered;
			},
			isFiltered: function() {
				return this.filterCPN || this.filterVPN || this.filterProductName;
			},
		},		
		delimiters: ["<%", "%>"],
	});
}
