<template>
  <div v-if="consolidator" class="consolidator-body">
    <div class="row">
      <div class="col-md-12">
        <div class="row white-box">
          <div class="attachments col-sm" style="padding-top: 5px;">
            <strong>Attachments:</strong>
            <span v-if="consolidator.id !== null">
              <label for="consolidatorAttachment" style="cursor: pointer;">
                <i class="fas fa-plus-circle fa-1x"></i>
              </label>
              <input type="file" id="consolidatorAttachment" ref="consolidatorAttachment" style="display: none" multiple
                @change="addAttachments()">
              <ul v-for="attachment in consolidator.consolidatorAttachments">
                <li>
                  <i class="far fa-file fa-1x"></i> {{ attachment.originalName }}
                  <i class="fas fa-minus-circle fa-1x" @click="removeAttachment(attachment.id)" style="cursor: pointer;"></i>
                </li>
              </ul>
            </span>            
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center spinner" v-if="isSpinner"></div>
  </div>
</template>

<script>
const axios = require('axios').default;

export default {
  name: "ConsolidatorEdit",
  data: function () {
    return {
      isSpinner: false,
      consolidator: undefined,
      isDirty: false,
    }
  },
  methods: {
    initEmptyConsolidator: function () {
      return {
        id: null,
        consolidatorId: '',
        consolidatorName: '',
        status: 0,
      };
    },
    loadData: function () {
      axios.get('/settings/consolidators/' + this.id + '/json')
        .then(this.loadDataSuccess)
        .catch(this.loadDataError)
        .finally(this.loadDataFinally);
    },
    loadDataSuccess: function (response) {
      this.consolidator = response.data.data;
    },
    loadDataError: function (error) {
      console.debug(error);
      let message = 'There has been an error while loading the data.';
      if (error.response && error.response.data && error.response.data.errors) {
        message += `\n`;
        for (let key of Object.keys(error.response.data.errors)) {
          let errorMessage = error.response.data.errors[key];

          message += `\n${key}: ${errorMessage}`;
        }
      }
      this.flash('error', message);
    },
    loadDataFinally: function () {
    },
    flash: function (type, message) {
      this.$emit('flash', { type: type, message: message });
    },
    addAttachments: function () {
      let uploadedAttachments = this.$refs.consolidatorAttachment.files;
      let formData = new FormData();
      for (let i = 0; i < uploadedAttachments.length; i++) {
        let uploadedAttachment = uploadedAttachments[i];
        formData.append(`consolidatorAttachment[${i}]`, uploadedAttachment);
      }

      this.isSpinner = true;

      axios.post('/settings/consolidators/' + this.consolidator.id + '/attachment/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(this.attachmentUploadSuccess)
        .catch(this.attachmentError)
        .finally(this.attachmentFinally('Attachment successfully uploaded.'));
    },
    attachmentUploadSuccess: function (response) {
      //console.debug(response);
      this.consolidator.consolidatorAttachments.push({ "id": response.data.consolidatorAttachment.id, "originalName": response.data.consolidatorAttachment.originalName });
    },
    attachmentError: function (error) {
      console.debug(error);
      let message = 'There has been an error while updating the attachments. Please check the validation errors and try again.';
      // for (let error of error.)
      if (error.response && error.response.data && error.response.data.errors) {
        message += `\n`;
        for (let key of Object.keys(error.response.data.errors)) {
          let errorMessage = error.response.data.errors[key];

          message += `\n${key}: ${errorMessage}`;
        }
      }
      alert(message)
    },
    attachmentFinally: function (message) {
      this.isSpinner = false;
      this.resetFileInput();  // Input zurücksetzen
      this.flash('success', message);
    },
    removeAttachment: function (consolidatorAttachmentId) {
      let consolidatorAttachment = null;
      for (let attachment of this.consolidator.consolidatorAttachments) {
        if (attachment.id === consolidatorAttachmentId) {

          consolidatorAttachment = attachment;
          break;
        }
      }
      if (!consolidatorAttachment) {
        return Promise.reject(`Invalid order type ID ${consolidatorAttachmentId}`);
      }

      this.isSpinner = true;

      axios.post(`/settings/consolidators/${this.consolidator.id}/attachment/${consolidatorAttachmentId}/remove`)
        .then(response => {
            this.attachmentRemoveSuccess(response);            
        })
        .catch(this.attachmentError)
        .finally(this.attachmentFinally('Attachment successfully removed.'));
    },
    resetFileInput() {
        this.$refs.consolidatorAttachment.value = null;  // Datei-Input zurücksetzen
    },
    attachmentRemoveSuccess: function (response) {
      //console.debug(response);
      let attachmentId = response.data.consolidatorAttachment.id;
      for (let [i, consolidatorAttachment] of this.consolidator.consolidatorAttachments.entries()) {
        if (consolidatorAttachment.id === attachmentId) {
          this.consolidator.consolidatorAttachments.splice(i, 1);
        }
      }
    },
  },
  props: {
    'id': {
      type: Number,
      default: undefined,
    }
  },
  computed: {

  },
  mounted: function () {
    if (this.id) {
      this.loadData();
    } else {
      this.consolidator = this.initEmptyConsolidator();
    }
  }
}
</script>
