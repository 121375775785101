const axios = require('axios').default;
import ConsolidatorEdit from "../components/ConsolidatorEdit";
import VueFlashbag from '../components/VueFlashbag';
// import DeleteConsolidator from "../components/DeleteConsolidator";

if (document.querySelector('.vue-consolidator-edit')) {
    Vue.component('consolidator-edit', ConsolidatorEdit);
    Vue.component('vue-flashbag', VueFlashbag);
    // Vue.component('delete-consolidator', DeleteConsolidator)

    document.appConsolidatorEdit = new Vue({
        el: '.vue-consolidator-edit',
        name: 'Consolidator Edit',
        data: {
            spinner: false,
            flashbag: {
                success: [],
                error: [],
                info: [],
            },
        },
        methods: {            
            flash: function(type, message) {
                this.flashbag.success = [];
                this.flashbag.error = [];
                this.flashbag.info = [];
                this.flashbag[type] = [message];
            }
        },
        computed: {

        },
        watch: {

        },
        mounted() {

        },
        delimiters: ["<%", "%>"]
    });
}

