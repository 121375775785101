<template>
    <div>
        <div class="modal fade" id="sendCT">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="popup-title">
                        <span class="modal-title">Are you sure?</span>
                    </div>
                    <div class="modal-body popup-body">
                        <span class="text-center">Are you sure you want to upload bid-sheet to Crunch Time?<br>
                        After confirmation no changes will be allowed.</span>
                    </div>
                    <div class="popup-buttons">
                      <button type="button" class="popup-confirm" data-dismiss="modal" @click="confirm">Confirm</button>
                      <button type="button" class="popup-cancel" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <button class="buttons" :disabled="! canUploadToCt" data-toggle="modal" data-target="#sendCT">Send to CT</button>
    </div>
</template>

<script>
export default {
    name: "UploadToCrunchTime",
    methods: {
      confirm: function () {
        this.$emit('confirmed');
      }
    },
    props: ['is-dirty', 'can-upload-to-ct']
}
</script>
