const axios = require('axios').default;
import Sorter from '../components/Sorter';
import Pagination from "../components/Pagination";

if (document.querySelector('.vue-po-overview')) {

    const PO_STATUS_ENUM = {
        "STATUS_READY_FOR_RECEIPT": 5,
        "STATUS_READY_FOR_CONFIRMATION": 10,
        "STATUS_CANCELED": 20,
        "STATUS_CONFIRMED": 30,
        "STATUS_READY_FOR_CONSOLIDATION": 40,
        "STATUS_READY_FOR_INVOICE": 51,
        "STATUS_CLOSED": 60,
    };

    Vue.component('sorter', Sorter);
    Vue.component('pagination', Pagination);
    new Vue({
        el: '.vue-po-overview',
        name: 'PO Overview',
        data: {
            pos: [],
            poCode: "",
            vendorName: "",
            vendorCode: "",
            primShippingType: "",
            vesselName: "",
            boardingDateFrom: "",
            boardingDateTo: "",
            deliveryDateFrom: "",
            deliveryDateTo: "",
            poStatus: "",
            selectedPos: [],
            arePosLoading: false,
            isProcessing: false,
            sorters: {
                poCode: null,
                vendorName: null,
                vendorCode: null,
                primShippingType: null,
                location: null,
                boardingDate: null,
                deliveryDate: null,
                total: null,
                weight: null,
                poStatus: null,
            },
            orderBy: 'b.id',
            orderDir: 'DESC',
            page: 1,
            limit: 50,
            paginationInfo: null,
            spinner: false,
        },
        methods: {
            formatNumberToGerman: function(value) {
                const number = parseFloat(value);
                if (isNaN(number)) {
                    return value; // Wenn der Wert keine gültige Zahl ist, gib den ursprünglichen Wert zurück
                }
                return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number);
              },
            onFilterInput: function () {
                this.page = 1;
                this.fetchPos();
            },
            fetchPos: function () {
                let self = this;
                self.arePosLoading = true;
                self.pos = [];

                axios.get('/po/json', {
                    params: this.poFilters
                })
                    .then(function (response) {                        
                        const data = response.data;
                        self.pos = data.items;
                        self.paginationInfo = data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
                    .then(function () {
                        // always executed
                        self.arePosLoading = false;
                    });
            },
            downloadOverview: function () {  
              // Alert/Confirm Check Filters
              if (
								this.poFilters.poCode != "" ||
								this.poFilters.vendorName != "" ||
								this.poFilters.vesselName != "" ||
								this.poFilters.primShippingType != "" ||
								this.poFilters.boardingDateFrom != "" ||
                this.poFilters.boardingDateTo != ""||
                this.poFilters.deliveryDateFrom != "" ||
                this.poFilters.deliveryDateTo != "" ||
                this.poFilters.poStatus != ""
							) {
                if (!confirm("Attention! You have set a filter. Only the filtered data will be exported.")) {
                  return;
                }
							}

              //TODO - Check Order
              
              this.startXLSXDownload();

              // Generiere den Zeitstempel im Format YYYY-MM-DD_HH-MM-SS
              const timestamp = new Date().toISOString().slice(0, 19).replace(/[-:T]/g, '');  // YYYYMMDD_HHMMSS
              const filename = 'PO-Overview_' + timestamp + '.xlsx';  // Dateiname mit Zeitstempel

              axios({
                url: '/po/downloads/overview/xlsx',
                method: 'GET',
                responseType: 'blob',  // Wichtig: Die Antwort als Blob behandeln
                params: this.poFilters
              })
              .then(function (response) {                        
                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);  // Dateiname
                document.body.appendChild(link);
                link.click();  // Automatisch den Download auslösen
                window.URL.revokeObjectURL(url);  // Aufräumen
              })
              .catch(function (error) {
                  console.log(error);
              })
              .then(function () {
                  // always executed
              });
            },
            updateSorters: function(orderBy, orderDir) {
                this.orderBy = orderBy;
                this.orderDir = orderDir;
                this.fetchPos();
            },
            toFixed: function (value, decimalNumber) {
                const parsedValue = parseFloat(value);
                if (isNaN(parsedValue)) {
                    return value;
                }

                return parsedValue.toFixed(decimalNumber || this.singlePricesDecimal); // geändert am 8.3.2022 - old - return parseFloat(parsedValue.toFixed(decimalNumber || this.singlePricesDecimal));
            },
            changePage(page) {
                this.page = page;
            },
            startXLSXDownload: function() {
              if (this.isDirty) {
                return;
              } else {
                this.spinner = true;
                this.setCookie("downloadStarted", 0, 100); //Expiration could be anything... As long as we reset the value
                setTimeout(this.checkDownloadCookie, 1000); //Initiate the loop to check the cookie.
              }
            },
            setCookie: function(name, value, expiracy) {
              var exdate = new Date();
              exdate.setTime(exdate.getTime() + expiracy * 1000);
              var c_value =
                escape(value) +
                (expiracy == null ? "" : "; expires=" + exdate.toUTCString());
              document.cookie = name + "=" + c_value + "; path=/";
            },
            getCookie: function(name) {
              var i,
                x,
                y,
                ARRcookies = document.cookie.split(";");
              for (i = 0; i < ARRcookies.length; i++) {
                x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
                y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
                x = x.replace(/^\s+|\s+$/g, "");
                if (x == name) {
                  return y ? decodeURI(unescape(y.replace(/\+/g, " "))) : y; //;//unescape(decodeURI(y));
                }
              }
            },
            checkDownloadCookie: function() {
              if (this.getCookie("downloadStarted") == 1) {
                this.setCookie("downloadStarted", "false", 100); //Expiration could be anything... As long as we reset the value
                this.spinner = false;
              } else {
                this.downloadTimeout = setTimeout(this.checkDownloadCookie, 1000); //Re-run this function in 1 second.
              }
            },
        },
        computed: {
            poFilters: function () {
                let filters = {
                    poCode: this.poCode,
                    vendorName: this.vendorName,
                    vendorCode: this.vendorCode,
                    vesselName: this.vesselName,
                    primShippingType: this.primShippingType,
                    boardingDateFrom: this.boardingDateFrom,
                    boardingDateTo: this.boardingDateTo,
                    deliveryDateFrom: this.deliveryDateFrom,
                    deliveryDateTo: this.deliveryDateTo,
                    poStatus: this.poStatus,
                    orderBy: this.orderBy,
                    orderDir: this.orderDir,
                    page: this.page,
                    limit: this.limit
                };

                return filters;
            }
        },
        watch: {
            page: function(newPage, oldPage) {
                this.fetchPos();
            }
        },
        mounted() {
            this.fetchPos();
        },
        delimiters: ["<%", "%>"]
    });
}
