const axios = require('axios').default;

if(document.querySelector('#app')) {
    document.appVendorTable = new Vue({
        el: '#app',
        name: "Vendor Table",
        data: {
            vendors: [],
            uploadedFiles: [],
            isFilesLoaded: false,
            isSubmitInProgress: false,
            btnSave: 'Save',
            filterVendorName: '',
            filterVendorCode: '',
            spinner: false,
            spinnerMount: false,
        },
        methods: {
            addVendor: function(emailArray) {
                emailArray.push('');
            },
            removeVendor: function(emailArray, index) {
                emailArray.splice(index, 1);
            },
            save: function (vendor) {
                vendor.email_cc = this.removeInvalidEmails(vendor.email_cc);
                vendor.email_reply_to = this.removeInvalidEmails(vendor.email_reply_to);
                vendor.email_to = this.removeInvalidEmails(vendor.email_to);
                let url = '/settings/vendors/' + vendor.code + '/save';
                let self=this;
                this.isSubmitInProgress = true;
                this.btnSave = 'Saving'
                axios.post(url, vendor)
                    .then(function (response) {
                        self.isSubmitInProgress = false;
                        self.btnSave = 'Save'
                    })
                    .catch(function (error) {
                        alert("There has been an error while saving.")
                        console.log(error);
                    });
            },
            removeInvalidEmails: function (emailsArray) {
                let pattern = /^\S+@\S+\.\S+/;
                for (let i = 0; i < emailsArray.length; i++) {
                    if (emailsArray[i] === "" || ! (emailsArray[i].match(pattern))) {
                        emailsArray.splice(i,1);
                    }
                }
                return emailsArray;
            },
            addAttachment: function (vendorIndex, vendorId) {

                this.spinner = true;
                let fileInput = document.getElementById(['vendorAttachment-' + vendorIndex]);
                let uploadedAttachments = fileInput.files;

                let formData = new FormData();
                for (let i = 0; i < uploadedAttachments.length; i++) {
                    let uploadedAttachment = uploadedAttachments[i];
                    if (uploadedAttachment.type === "application/pdf") {
                        formData.append(`vendorAttachment[${i}]`, uploadedAttachment);
                    } else {
                        alert('Only PDF files are allowed.');
                        return; 
                    }
                }

                axios.post('/settings/vendors/' + vendorId + '/attachment/upload', formData, {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                  })
                      .then(response => {
                        // alert(response.data.status); 
                    })
                      .catch(error => {
                        alert('Error: ' + error.message); 
                      })
                      .finally(() => {
                        this.loadVendors(); // refresh list 
                        fileInput.value = ''; //Bugfix, damit die Funktion aufgerufen wird, wenn die Datei zweimal hintereinander im Inputfeld ausgewählt wird
                      });

            },

            removeAttachment: function(vendorId, attachmentId) {

                this.spinner = true;
                axios.post(`/settings/vendors/${vendorId}/attachment/${attachmentId}/remove`)

                .then(response => {
                    //alert(response.data.status); // Zeigt den Status in einem Alert an
                    //console.log(response);
                })
                  .catch(error => {
                    alert('Error: ' + error.message); // Zeigt eine Fehlermeldung an
                  })
                  .finally(() => {
                    this.loadVendors(); // refresh list
                  });
            },
                loadVendors: function() {
                    this.spinner = true; 
                    axios.get('/settings/vendors/json')
                        .then((response) => {
                            this.vendors = response.data;
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                        .finally(() => {
                            this.spinner = false;
                        });                                        
            },

            addDefaultAttachment: function() {

                this.spinner = true;
                let fileInput = document.getElementById('default-attachment');
                let uploadedFiles = fileInput.files;
            
                if (uploadedFiles.length === 0) {
                    alert('Please select a file.');
                    return;
                }
            
                let formData = new FormData();
                for (let i = 0; i < uploadedFiles.length; i++) {
                    let uploadedFile = uploadedFiles[i];
                    if (uploadedFile.type === "application/pdf") {  // Optional: Check for PDF
                        formData.append(`defaultAttachment[${i}]`, uploadedFile);
                    } else {
                        alert('Only PDF files are allowed.');
                        return;
                    }
                }

                axios.post('/settings/vendors/default-attachment/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    if (response.data.status === 'success') {
                        // alert('File uploaded successfully.');
                    } else {
                        alert('Error: ' + response.data.message);
                    }
                })
                .catch(error => {
                    alert('Error uploading file: ' + error.message);
                    console.log(error);
                })
                .finally(() => {
                    this.loadUploadedFiles(); 
                    fileInput.value = '';  // Clear the input field for future uploads
                });
            

            },

            loadUploadedFiles() {
                
                this.spinner = true;
                axios.get('/settings/vendors/default-attachment/uploaded-files')
                    .then(response => {
                        if (response.data.status === 'success') {
                            this.uploadedFiles = response.data.files; 
                        } else {
                            alert('Error: ' + response.data.message);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        alert('Failed to load files.');
                    })
                    .finally(() => {
                            this.isFilesLoaded = true;
                            this.spinner = false;
                                                
                    });
            },

            removeDefaultAttachment(fileName) {
                this.spinner = true;
                if (confirm(`Are you sure you want to delete "${fileName}"?`)) {
                    axios.post('/settings/vendors/default-attachment/remove', { fileName: fileName })
                        .then(response => {
                            if (response.data.status === 'success') {
                                this.loadUploadedFiles(); 
                            } else {
                                alert('Error: ' + response.data.message);
                            }
                        })
                        .catch(error => {
                            alert('Error removing file: ' + error.message);
                        });
                }
            },
            

        },
        computed: {
            vendorsFiltered: function() {
                let filtered = this.vendors;
                if (this.filterVendorName.length > 0) {
                    filtered = filtered.filter(item => {
                        return item.name.toLowerCase().indexOf(this.filterVendorName.toLowerCase()) > -1;
                    });
                }
                if (this.filterVendorCode.length > 0) {
                    filtered = filtered.filter(item => {
                        return item.code.toLowerCase().indexOf(this.filterVendorCode.toLowerCase()) > -1;
                    });
                }
                return filtered;
            }
        },

        mounted: function () {
                let self = this;
                self.loadUploadedFiles();
                this.spinnerMount = true;
            
                axios.get('/settings/vendors/json')
                    .then((response) => {
                        self.vendors = response.data;
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {
                        self.spinnerMount = false;
                });        

        },
        delimiters: ["<%", "%>"]
    })
}
