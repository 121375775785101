if (document.querySelector('.vue-consolidator-overview')) {
  document.appConsolidatorOverview = new Vue({
    el: '.vue-consolidator-overview',
    name: "Consolidator Overview",
    data: {
      consolidators: [],
      filterConsolidatorName: '',
      filterConsolidatorCode: '',
    },
    mounted: function () {
      this.consolidators = JSON.parse(
        Buffer.from(this.$refs.consolidatorJson.dataset.consolidatorJson, "base64")
      );
    },
    computed: {
      consolidatorsFiltered: function () {
        let filtered = [];
        if (this.consolidators) {
          filtered = this.consolidators;
          if (this.filterConsolidatorName.length > 0) {
            filtered = filtered.filter(item => {
              return item.name.toLowerCase().indexOf(this.filterConsolidatorName.toLowerCase()) > -1;
            });
          }
          if (this.filterConsolidatorCode.length > 0) {
            filtered = filtered.filter(item => {
              return item.code.toLowerCase().indexOf(this.filterConsolidatorCode.toLowerCase()) > -1;
            });
          }
        }
        return filtered;
      }
    },
    delimiters: ["<%", "%>"]
  })
}
